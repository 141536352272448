body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
}

/* li {
  list-style-type: none;
  list-style: none;
  font-size: large;
  margin: 10px;
} */
/* .k-tilelayout-item-header {
  padding: 6px 8px !important;
}

.k-tilelayout-item-header.k-card-header {
  color: #ffffff;
  font-size: 16px;
  background-color: #7f84ab;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  font-family: "Pretendard-B";
} */
.k-tilelayout .k-card {
  border-radius: 0px !important;
}

.k-tilelayout {
  gap: 8px !important;
  height: 100%;
}

.side-navigation-panel * {
  border-color: #0d0e12 !important;
  border-style: none !important;
  color: white;
}
.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option {
  padding: 1.2rem 1.5rem !important;
}
.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option-selected {
  background-color: #1c1c21 !important;
}

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option:hover {
  background-color: #1c1c21 !important;
}
.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-inner
  .side-navigation-panel-select-inner-wrap
  .side-navigation-panel-select-inner-option {
  padding: 0.8rem 4rem !important;
}
.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-inner
  .side-navigation-panel-select-inner-wrap
  .side-navigation-panel-select-inner-option:hover {
  background-color: #1c1c21 !important;
}
.card .card-header {
  min-height: 60px !important;
  border-bottom: none !important;
}
::-webkit-scrollbar-thumb:hover {
  /* background: #555 !important; */
  /* 마우스 오버 시 핸들의 색상 */
  width: 8px !important; /* 마우스 오버 시 핸들의 너비를 더 두껍게 */
  height: 8px !important; /* 마우스 오버 시 핸들의 너비를 더 두껍게 */
}
.k-svg-icon > svg {
  /* fill: currentColor; */
  flex: 1 0 auto !important;
  font-size: 10px !important;
  color: black;
}
.k-table-thead {
  text-align: center;
}
.k-table-td {
  /* text-align: center; */
  padding: 7px;
}
.k-sort-icon {
  width: 20px;
}
.k-cell-inner .k-link {
  display: flex;
  /* text-align: center; */
}

tr {
  border-bottom: 1px dashed lightgray !important;
}
th {
  padding: 10px 2px;
}
.k-table-th .k-header {
  text-align: center !important;
}
.k-table-thead .k-table-row span {
  text-align: center;
  margin: auto;
}

.ki-duotone,
.ki-outline,
.ki-solid {
  line-height: 1;
  font-size: 16px !important;
  color: var(--bs-text-muted);
}
nav::-webkit-scrollbar {
  width: 0 !important;
  background: transparent; /* Optional: Set a transparent background color */
}
